import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import store from '@/store'

const OPEN_TARGETS = [
  {
    key: 'replace_location',
    label: '新页面'
  }, {
    key: 'new_window',
    label: '新窗口'
  }, {
    key: 'in_search',
    label: '搜索融合'
  }
]

const DATE_INTERACTIONS = {
  MAX: 'MAX',
  MIN: 'MIN',
  RANGE: 'RANGE',
  NONE: 'NONE'
}

const DATE_INTERACTION_OPTIONS = [
  {
    key: DATE_INTERACTIONS.MAX,
    label: '范围基值（大）'
  }, {
    key: DATE_INTERACTIONS.MIN,
    label: '范围基值（小）'
  }, {
    key: DATE_INTERACTIONS.RANGE,
    label: '范围值'
  }, {
    key: DATE_INTERACTIONS.NONE,
    label: '不交互'
  }
]

const CHART_OBJECTS = [
  {
    key: 'chart',
    name: '智能图表',
    icon: 'bar-chart',
    desc: '智能图表说明'
  },
  {
    key: 'metrics',
    name: '指标值',
    icon: 'bar-chart',
    desc: '指标值说明'
  },
  {
    key: 'dimension',
    name: '维度值',
    icon: 'bar-chart',
    desc: '维度值说明'
  },
  {
    key: 'datetime',
    name: '日期时间',
    icon: 'bar-chart',
    desc: '日期时间说明'
  }
]

const CHART_STATUS = {
  NULL: '未配置',
  LOADING: '加载中…',
  RENDERING: '图形渲染中…',
  ERROR: '发生错误',
  EMPTY: '数据为空',
  DONE: '已就绪'
}

/**
 * 分析报告模型
 *
 * 图表的具体配置，不放在内容里，放在属性里
 */
class RichReportModel {
  id = ''
  title = ''
  description = ''
  dataRegionId = ''
  type = 'RICH_REPORT'
  // 富文本内容，解析自 data
  content = ''
  /**
   * 报告日期时间
   * 作用于“时间日期”对象，默认图表中可时间交互但没有默认值的对象
   * @type {string}
   */
  datetime = ''
  // 图表，ID和content中的元素ID对应
  /**
   * @type {[RichReportChartModel]}
   */
  charts = []
  /**
   * 打开方式
   * @type {string}
   */
  openTarget = 'replace_location'
  /**
   * 语音关键词列表
   * @type {[]}
   */
  keywordList = []
  /**
   * 参数，用于传递搜索中展示时的关键词
   * 可以为字符串，数组（已经解析的关键词）
   * @type {null}
   */
  params = null
  /**
   * 动态值
   * @type {RichReportChartInteractionModel}
   */
  interaction = null

  constructor(args) {
    if (_.isObject(args)) {
      if (args.id) this.id = args.id
      if (args.title) this.title = args.title
      if (args.description) this.description = args.description
      if (args.dataRegionId) this.dataRegionId = args.dataRegionId
      if (args.data) {
        try {
          const { content, datetime, charts, openTarget, keywordList } = JSON.parse(args.data)
          this.content = decodeURIComponent(content)
          if (datetime) this.datetime = datetime
          if (charts && _.isArray(charts)) {
            this.charts = charts.map(chart => {
              return new RichReportChartModel(chart)
            })
          }
          if (openTarget) this.openTarget = openTarget
          if (keywordList && _.isArray(keywordList)) this.keywordList = keywordList
        } catch (e) {
          console.error(e)
        }
      }
    }
    if (!this.dataRegionId) this.dataRegionId = store.state.speech.dataRegionId
    // 动态值，用于图表交互
    this.interaction = new RichReportChartInteractionModel()
  }

  /**
   * 获取保存至数据库的模型
   */
  getPersistenceModel () {
    const { id, title, description, dataRegionId, type, content, datetime, charts: originCharts, openTarget, keywordList } = this
    const charts = []
    originCharts.forEach(chart => {
      charts.push(chart.getPersistenceModel())
    })
    // 去掉image中的base64代码，减小体积
    const lightContent = content.replace(/src="data:image[^"]*"/g, 'src=""')
    return {
      id,
      title,
      description,
      dataRegionId,
      type,
      data: JSON.stringify({ content: encodeURIComponent(lightContent), datetime, charts, openTarget, keywordList })
    }
  }
}

/**
 * 分析报告图表模型
 * @class RichReportChartModel
 */
class RichReportChartModel {
  id = ''
  type = 'chart' // chart, metrics, dimension, datetime
  chartType = 'Line'
  keyword = ''
  /**
   * 用于显示的关键词，需用户输入
   * @type {string}
   */
  keywordDisplay = ''
  /**
   * 显示关键词
   * 如：[省份：湖南]，[营业额：￥123,221,221]
   * @type {boolean}
   */
  showKeyword = false
  /**
   * 图表皮肤（色系）
   * @type {string}
   */
  chartTheme = ''
  /**
   * 预览内容
   * 用于指标、维度值图表
   * @type {string}
   */
  previewData = ''
  width = 600
  height = 400
  style = ''
  /**
   * 原图表是否已经存在时间关键词，如果存在，外部传入的时间，就作为时间基点处理
   * 可选值: MAX, MIN, RANGE, NONE
   * @type {string}
   */
  dateInteraction = 'MAX'
  /**
   * 日期交互默认值
   * @type {string}
   */
  dateDefault = ''
  /**
   * 参数
   * 限制参数传入，只接收已定义的参数
   * 目前只用于 DIMENSION 维度过滤
   * @type {[RichReportChartParamModel]}
   */
  params = []
  // ******************* 编辑时属性 *******************
  // ******************* 不用保存 *******************
  /**
   * 可用图表类型
   * @type {[string]}
   */
  chartTypes = ['KeyValue']
  /**
   * 缩略图
   * @type {string}
   */
  thumbnail = ''
  /**
   * 当前状态
   * @type {string}
   */
  status = CHART_STATUS.NULL
  name = ''
  icon = 'fund'
  replaced = false
  // interaction
  prepared = false
  /**
   * @type {RichReportChartInteractionModel}
   */
  interaction = null

  constructor(args) {
    if (_.isObject(args)) {
      const fields = ['id', 'type', 'chartType', 'keyword', 'keywordDisplay', 'showKeyword', 'chartTheme',
        'previewData', 'width', 'height', 'style', 'dateInteraction', 'dateDefault', 'thumbnail']
      // 动态值： 'params', 'chartTypes', 'replaced', 'prepared', 'name', 'icon', 'status'
      // 数组对象： 'params'
      fields.forEach(field => {
        if (args[field]) this[field] = args[field]
      })
      if (_.isArray(args.params)) {
        this.params = args.params.map(p => {
          return new RichReportChartParamModel(p)
        })
      }
    }
    if (!this.id) this.id = uuid()
    const object = CHART_OBJECTS.find(o => o.key === this.type)
    this.name = object.name
    if (!this.previewData) this.previewData = object.name
    // 动态值，用于图表交互
    this.interaction = new RichReportChartInteractionModel()
  }

  /**
   * 获取保存至数据库的模型
   */
  getPersistenceModel () {
    const {
      id,
      type,
      chartType,
      keyword,
      keywordDisplay,
      showKeyword,
      chartTheme,
      previewData,
      width,
      height,
      style,
      dateInteraction,
      dateDefault
    } = this
    const params = _.cloneDeep(this.params)
    return {
      id,
      type,
      chartType,
      keyword,
      keywordDisplay,
      showKeyword,
      chartTheme,
      previewData,
      width,
      height,
      style,
      dateInteraction,
      dateDefault,
      params
    }
  }
}

/**
 * 分析报告图表参数模型
 * TODO 时间范围分开（唯一）
 */
class RichReportChartParamModel {
  // 类型：时间维度，业务维度
  type = 'DIMENSION' // DATETIME
  key = ''
  value = ''

  constructor(args) {
    if (_.isObject(args)) {
      if (args.type) this.type = args.type
      if (args.key) this.key = args.key
      if (args.value) this.value = args.value
    }
  }
}

/**
 * 时间交互
 */
class RichReportChartDatetimeModel {
  /**
   * BaseDate
   * 用于在解析关键词时，决定是否穿越时空
   * @type {{dateRange: {single: boolean, dateFieldType: string, start: string, end: string}, useEnd: boolean}}
   */
  baseDate = {
    dateRange: {
      dateFieldType: '',
      start: '',
      end: '',
      single: true
    },
    useEnd: true
  }
  keyword = ''

  constructor(keywordMatch) {
    if (_.isObject(keywordMatch['dateRange'])) {
      this.keyword = keywordMatch['matchedValue']
      this.baseDate.dateRange = _.clone(keywordMatch['dateRange'])
    }
  }

  setUseEnd (useEnd) {
    this.baseDate.useEnd = useEnd
  }

  cloneBaseDateWithSetUseEnd (useEnd) {
    const baseDate = _.cloneDeep(this.baseDate)
    baseDate.useEnd = useEnd
    return baseDate
  }
}

class RichReportChartInteractionModel {
  /**
   * @type {RichReportChartDatetimeModel}
   */
  datetime = null
  keywordList = []

  constructor(keywordMatch) {
    if (_.isArray(keywordMatch)) {
      this.parseFromKeywordMatch(keywordMatch)
    }
  }

  reset () {
    this.datetime = null
    this.keywordList.splice(0)
  }

  /**
   * 只接受“日期”、“维度值”类型关键词
   * @param {Array} keywordMatch
   */
  parseFromKeywordMatch (keywordMatch) {
    keywordMatch.forEach(k => {
      if (k['keywordCategory'] === 'DATETIME') {
        this.datetime = new RichReportChartDatetimeModel(k)
      } else if (k['keywordCategory'] === 'DIMENSION_VALUE') {
        this.keywordList.push(k)
      }
    })
  }

  /**
   * append RichReportChartInteractionModel
   * @param {RichReportChartInteractionModel} interaction
   */
  appendInteraction (interaction) {
    if (interaction.datetime) {
      this.datetime = _.cloneDeep(interaction.datetime)
    }
    interaction.keywordList.forEach(keyword => {
      if (!this.keywordList.find(k => k['id'] === keyword['id'])) {
        this.keywordList.push(keyword)
      }
    })
  }
}

export {
  RichReportModel,
  RichReportChartModel,
  RichReportChartParamModel,
  RichReportChartDatetimeModel,
  RichReportChartInteractionModel,
  OPEN_TARGETS,
  CHART_OBJECTS,
  DATE_INTERACTIONS,
  DATE_INTERACTION_OPTIONS,
  CHART_STATUS
}
