export default [
  {
    colors: [
      '#d87c7c',
      '#919e8b',
      '#d7ab82',
      '#6e7074',
      '#61a0a8',
      '#efa18d',
      '#787464',
      '#cc7e63',
      '#724e58',
      '#4b565b'
    ],
    background: '#fef8ef',
    name: 'vintage'
  },
  {
    colors: [
      '#dd6b66',
      '#759aa0',
      '#e69d87',
      '#8dc1a9',
      '#ea7e53',
      '#eedd78',
      '#73a373',
      '#73b9bc',
      '#7289ab',
      '#91ca8c',
      '#f49f42'
    ],
    background: '#333333',
    name: 'dark-theme'
  },
  {
    colors: [
      '#516b91',
      '#59c4e6',
      '#edafda',
      '#93b7e3',
      '#a5e7f0',
      '#cbb0e3'
    ],
    background: '',
    name: 'westeros'
  },
  {
    colors: [
      '#893448',
      '#d95850',
      '#eb8146',
      '#ffb248',
      '#f2d643',
      '#ebdba4'
    ],
    background: '',
    name: 'essos'
  },
  {
    colors: [
      '#4ea397',
      '#22c3aa',
      '#7bd9a5',
      '#d0648a',
      '#f58db2',
      '#f2b3c9'
    ],
    background: '',
    name: 'wonderland'
  },
  {
    colors: [
      '#3fb1e3',
      '#6be6c1',
      '#626c91',
      '#a0a7e6',
      '#c4ebad',
      '#96dee8'
    ],
    background: '',
    name: 'walden'
  },
  {
    colors: [
      '#fc97af',
      '#87f7cf',
      '#f7f494',
      '#72ccff',
      '#f7c5a0',
      '#d4a4eb',
      '#d2f5a6',
      '#76f2f2'
    ],
    background: '#293441',
    name: 'chalk'
  },
  {
    colors: [
      '#c1232b',
      '#27727b',
      '#fcce10',
      '#e87c25',
      '#b5c334',
      '#fe8463',
      '#9bca63',
      '#fad860',
      '#f3a43b',
      '#60c0dd',
      '#d7504b',
      '#c6e579',
      '#f4e001',
      '#f0805a',
      '#26c0c0'
    ],
    background: '',
    name: 'infographic'
  },
  {
    colors: [
      '#2ec7c9',
      '#b6a2de',
      '#5ab1ef',
      '#ffb980',
      '#d87a80',
      '#8d98b3',
      '#e5cf0d',
      '#97b552',
      '#95706d',
      '#dc69aa',
      '#07a2a4',
      '#9a7fd1',
      '#588dd5',
      '#f5994e',
      '#c05050',
      '#59678c',
      '#c9ab00',
      '#7eb00a',
      '#6f5553',
      '#c14089'
    ],
    background: '',
    name: 'macarons'
  },
  {
    colors: [
      '#e01f54',
      '#001852',
      '#f5e8c8',
      '#b8d2c7',
      '#c6b38e',
      '#a4d8c2',
      '#f3d999',
      '#d3758f',
      '#dcc392',
      '#2e4783',
      '#82b6e9',
      '#ff6347',
      '#a092f1',
      '#0a915d',
      '#eaf889',
      '#6699ff',
      '#ff6666',
      '#3cb371',
      '#d5b158',
      '#38b6b6'
    ],
    background: '',
    name: 'roma'
  },
  {
    colors: [
      '#c12e34',
      '#e6b600',
      '#0098d9',
      '#2b821d',
      '#005eaa',
      '#339ca8',
      '#cda819',
      '#32a487'
    ],
    background: '',
    name: 'shine'
  },
  {
    colors: [
      '#8a7ca8',
      '#e098c7',
      '#8fd3e8',
      '#71669e',
      '#cc70af',
      '#7cb4cc'
    ],
    background: '#5b5c6e',
    name: 'purple-passion'
  },
  {
    colors: [
      '#ff715e',
      '#ffaf51',
      '#ffee51',
      '#797fba',
      '#715c87'
    ],
    background: '#333333',
    name: 'halloween'
  }
]
