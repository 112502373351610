<template>
  <span class="metrics-chart" :style="model.style">{{ displayData }}</span>
</template>

<script>
// import _ from 'lodash'
import { formatNumberThousands } from '@/utils/numberUtil'
import KeywordMatched from '@/utils/keywordMatched'
import ReportChartData from '@/utils/reportData'
import { RichReportChartModel, RichReportModel, RichReportChartInteractionModel } from '@/views/richReport/models'
import _ from 'lodash'
import { parseKeywordParams } from '@/views/richReport/util'
export default {
  name: 'MetricsChart',
  components: {
  },
  props: {
    model: {
      type: RichReportChartModel,
      default () {
        return new RichReportChartModel()
      }
    },
    richReportModel: {
      type: RichReportModel,
      default() {
        return new RichReportModel()
      }
    },
    interaction: {
      type: RichReportChartInteractionModel,
      default () {
        return new RichReportChartInteractionModel()
      }
    }
  },
  data () {
    return {
      dataLoading: false,
      reportChartData: {},
      reportDataRequest: null, // 没有，只看
      adviseXAxis: null, // 没有，只看
      keywordMatched: null,
      previousKeywordSearchHistoryId: null
    }
  },
  computed: {
    displayData () {
      console.log('.')
      const { showKeyword, keyword } = this.model
      const prefix = showKeyword ? `${keyword}：` : ''
      if (this.dataLoading) return `${prefix}XXX…`
      const dataItems = this.reportChartData['dataItems'] || []
      const metrics = this.reportChartData['metrics'] || []
      if (metrics.length === 0) return `${prefix}无合适数据`
      if (dataItems.length > 1) return `${prefix}结果不正确`
      if (dataItems.length === 1) {
        const dataItem = dataItems[0]
        const result = []
        Object.keys(metrics)
        metrics.forEach(key => {
          const val = formatNumberThousands(dataItem[key])
          const unit = this.getUnitByMetrics(key)
          result.push(`${val}${unit}`)
        })
        return prefix + result.join('，')
      }
      return `${prefix}暂无数据`
    }
  },
  methods: {
    getUnitByMetrics (metrics) {
      const dataUnits = this.reportChartData['units'] || []
      const _exist = dataUnits.find(item => item['metrics'].indexOf(metrics) > -1)
      return _exist ? _exist['unit'] : ''
    },
    parseKeyword() {
      const params = parseKeywordParams(this.richReportModel, this.model, this.interaction)
      if (_.isEmpty(params.keywords)) return
      if (_.isEmpty(params.dataRegionId)) return
      this.dataLoading = true
      this.$api.speech.parseMixKeyword(params).then(res => {
        const { keywordMatch, reportDataRequest } = res
        // 未匹配到不继续
        // 强制二次交互时，解析出不是二次交互不继续
        if (keywordMatch.length > 0) {
          this.keywordMatch = keywordMatch
          this.reportDataRequest = reportDataRequest
          this.previousKeywordSearchHistoryId = reportDataRequest.keywordSearchHistoryId
          this.keywordMatched = new KeywordMatched(params.keywords.join(''), keywordMatch)
          this.adviseXAxis = this.keywordMatched['adviseDatetimeAxis']
          this.getReportData(reportDataRequest)
        }
        if (keywordMatch.length === 0) {
          this.dataLoading = false
        }
      }).catch(() => {
        this.dataLoading = false
      })
    },
    getReportData(reportDataRequest) {
      this.$api.speech.getReportData(reportDataRequest).then(res => {
        this.reportChartData = new ReportChartData(res[0])
        this.dataLoaded = true
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.dataLoading = false
      })
    },
    chartInteractive(arg) {
      console.log('DashboardChart.chartInteractive', arg)
      const { action, params } = arg
      if (action === 'AppendKeyword') {
        const keywordList = params.keywordList.map(k => k['matchedValue'])
        this.parseKeyword(keywordList, true)
      }
    }
  },
  mounted() {
    this.$emit('chartReady')
    this.$nextTick(() => {
      this.parseKeyword(this.model.keyword)
    })
  },
  created() {
    this.$eventBus.$on('RICH_REPORT_INTERACT', this.chartInteractive)
  },
  destroyed() {
    this.$eventBus.$off('RICH_REPORT_INTERACT', this.chartInteractive)
  }
}
</script>
