<template>
  <div v-if="ready" class="dashboard-table-chart">
    <a-carousel :before-change="onChange" :autoplay="true" dotsClass="carousel-dots">
      <template v-for="(dataSource, index) in dataSources">
        <a-table
          class="data-table"
          size="small"
          :key="index"
          :style="tableStyle"
          :bordered="false"
          :columns="columns"
          :pagination="false"
          :dataSource="dataSource" />
      </template>
    </a-carousel>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import _, { debounce } from 'lodash'
import { getStrFullLength } from '@/utils/utils'
import { simplifyNumber } from '@/utils/numberUtil'
export default {
  name: 'TableChart',
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    height: {
      type: Number,
      default: 300
    },
    theme: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    tableStyle() {
      const { color } = this.theme
      if (color) {
        return {
          color: color
        }
      }
      return {}
    }
  },
  data () {
    return {
      ready: false,
      columns: [],
      dataSources: []
    }
  },
  methods: {
    onChange(a, b, c) {
      console.log(a, b, c)
    }
  },
  mounted () {
    const _columns = {}
    this.columns = JSON.parse(JSON.stringify(this.reportData['columns']))
    this.columns.map(item => {
      item['width'] = getStrFullLength(item['title']) * 14
      _columns[item['title']] = item
      return item
    })
    const dataSource = JSON.parse(JSON.stringify(this.reportData['dataItems']))
    dataSource.map(dataItem => {
      Object.keys(dataItem).forEach(key => {
        const _column = _columns[key]
        if (_column['axisType'] && _column['axisType'] === 'metrics') {
          dataItem[key] = simplifyNumber(dataItem[key]) + (_column['unit'] || '')
        }
        _column['width'] = Math.max(_column['width'], getStrFullLength(dataItem[key]) * 14)
      })
      dataItem['key'] = uuidv4()
    })
    this.columns[this.columns.length - 1]['width'] = 'auto'
    // calc table pagesize
    const pageSize = Math.floor((this.height - 15) / 38) - 1
    this.dataSources = _.chunk(dataSource, pageSize)
    this.ready = true
    this.chartRenderedDelay()
  },
  created () {
    this.chartRenderedDelay = debounce(() => {
      this.$emit('chartRendered')
    }, 1000)
  }
}
</script>

<style lang="less">
  .dashboard-table-chart {
    max-height: 100%;
    .ant-table-small {
      border: none;
    }
    .ant-table {
      color: inherit;
      .ant-table-thead {
        tr {
          th {
            color: inherit;
            border-bottom: 1px solid rgba(160, 160, 160, 0.6);
          }
        }
      }
      .ant-table-tbody {
        tr {
          td {
            border-bottom: 1px solid rgba(160, 160, 160, 0.4);
          }
          &:hover {
            td {
              background: none;
            }
          }
        }
      }
    }
    .carousel-dots {
      bottom: -5px;
      li {
        button {
          opacity: 1;
          background: rgba(200,200,200,0.3);
        }
        &.slick-active {
          button {
            opacity: 1;
            background: rgba(200,200,200,0.8);
          }
        }
      }
    }
  }
</style>
