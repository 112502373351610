<template>
  <span class="number-chart">
    <template v-if="dataSource.length === 0">暂无数据</template>
    <template v-else>
      {{ dataSource.join('，') }}
    </template>
  </span>
</template>

<script>
import { formatNumberThousands } from '@/utils/numberUtil'
export default {
  name: 'NumberChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    dataItems () {
      if (this.reportData['dataItems'] && this.reportData['dataItems'].length > 0) {
        return this.reportData['dataItems'][0]
      } else {
        return {}
      }
    },
    dataUnits () {
      return this.reportData['units'] || []
    },
    dataSource () {
      const _result = []
      const _keys = Object.keys(this.dataItems)
      _keys.forEach((_key) => {
        const _val = this.dataItems[_key]
        _result.push(formatNumberThousands(_val) + this.getUnitByMetrics(_key))
      })
      return _result
    }
  },
  methods: {
    getUnitByMetrics (metrics) {
      const _exist = this.dataUnits.find(item => item['metrics'].indexOf(metrics) > -1)
      return _exist ? _exist['unit'] : ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('chartRendered')
    })
  }
}
</script>
