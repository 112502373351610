<template>
  <div v-if="ready" class="message-table">
    <table class="message-table-table">
      <thead>
        <tr>
          <th v-for="(col) in columns" :key="col.key">
            <div class="th-div">
              <div class="th-text"> {{ col.title==='_hit_condition_'?'是否满足条件':col.title }} </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="data in currentData" :key="data.key" :style="{backgroundColor: data['_hit_condition_']?'#f3e486':''}">
          <td v-for="col in columns" :key="col.key">
            <div v-if="col.title==='_hit_condition_'"> {{ data[col.title]?'是':'否' }}</div>
            <div v-else>{{ simplifyNumberTemp(data[col.title]) + (col.unit || '') }}</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div ref="tablePagination" class="table-chart-pagination">
      <van-pagination
        v-if="dataSource.length > tablePageSize"
        v-model="currentPage"
        :total-items="dataSource.length"
        :items-per-page="tablePageSize"
        :show-page-size="showPageSize"
        force-ellipses
        @change="getCurrentData">
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
      </van-pagination>
    </div>
  </div>
</template>
<script>
import { simplifyNumber } from '@/utils/numberUtil'

export default {
  name: 'TableChart',
  components: {
  },
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {
        }
      }
    }
  },
  data () {
    return {
      ready: false,
      tablePageSize: 50,
      columns: [],
      dataSource: [],
      currentData: [],
      currentPage: 1,
      showPageSize: 5
    }
  },
  methods: {
    getCurrentData () {
      this.currentData = this.dataSource.slice((this.currentPage - 1) * this.tablePageSize, (this.currentPage - 1) * this.tablePageSize + this.tablePageSize)
    },
    calcShowPageNum () {
      const paginationWidth = this.$refs.tablePagination.offsetWidth
      // console.log('paginationWidth', paginationWidth)
      if (paginationWidth > 425) {
        this.showPageSize = Math.floor((paginationWidth - 100 * 2) / 36) - 2
      } else {
        this.showPageSize = Math.floor((paginationWidth - 50 * 2) / 36) - 2
      }
    },
    simplifyNumberTemp (val) {
      if (typeof val === 'number') {
        return simplifyNumber(val)
      } else {
        return val
      }
    }
  },
  mounted () {
    this.columns = JSON.parse(JSON.stringify(this.tableData.columns))
    this.dataSource = JSON.parse(JSON.stringify(this.tableData.dataItems))
    this.currentData = this.dataSource.slice((this.currentPage - 1) * this.tablePageSize, (this.currentPage - 1) * this.tablePageSize + this.tablePageSize)
    this.ready = true
    this.$nextTick(() => { // 修改分页器页码数量
      const _this = this
      this.calcShowPageNum()
      window.onresize = function () {
        _this.calcShowPageNum()
      }
    })
  }
}
</script>

<style lang="less">
  .message-table {
    max-height: 100%;
    .message-table-table {
      border-spacing: 0;
      border: none;
      width: 100%;
      td, th {
        font-size: 0.75rem;
        line-height: 28px;
        text-align: left;
      }
      thead tr {
        background-color: #ededed;
        .th-div {
          padding: 0 5%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .th-text {
            display: inline-block;
            font-weight: 500;
          }
        }
      }
      tbody {
        color: #6c6b6b;
        tr:nth-child(even) {
          background-color: #f8f8f8;
        }
        td div {
          transform: scale(0.9);
        }
      }
    }
    &-pagination {
      padding: 12px;
      display: flex;
      justify-content: center;
    }
  }
</style>
