<template>
  <div class="chart-chart" :style="wrapperStyle">
    <chart-all
      v-if="ready && chartType"
      :report-chart-data="reportChartData"
      :advise-x-axis="adviseXAxis"
      :chart-type="chartType"
      :chart-height="chartHeight"
      :chart-theme="chartTheme"
      @onChartRendered="onChartRendered"/>
    <div v-if="dataLoading" class="loading">
      <van-loading />
    </div>
  </div>
</template>

<script>
import _, { debounce } from 'lodash'
import KeywordMatched from '@/utils/keywordMatched'
import ReportChartData from '@/utils/reportData'
import { GenerateTheme } from '@/theme/chart/theme'
import colorGroups from '@/theme/chart/colors'

import {
  RichReportChartInteractionModel,
  RichReportChartModel,
  RichReportModel
} from '@/views/richReport/models'
import ChartAll from '@/views/richReport/view/ChartAll'
import { parseKeywordParams } from '@/views/richReport/util'
// import resize from 'vue-resize-directive'
export default {
  name: 'ChartChart',
  events: [],
  components: {
    ChartAll
  },
  props: {
    model: {
      type: RichReportChartModel,
      default() {
        return new RichReportChartModel()
      }
    },
    richReportModel: {
      type: RichReportModel,
      default() {
        return new RichReportModel()
      }
    },
    interaction: {
      type: RichReportChartInteractionModel,
      default () {
        return new RichReportChartInteractionModel()
      }
    }
  },
  data() {
    return {
      localParams: [],
      colorsName: '',
      dataLoading: false,
      ready: false,
      keywordMatch: null,
      reportDataRequest: null,
      previousKeywordSearchHistoryId: null,
      /**
       * @type {KeywordMatched}
       */
      keywordMatched: null,
      /**
       * @type {ReportChartData}
       */
      reportChartData: null,
      adviseXAxis: ''
    }
  },
  computed: {
    wrapperStyle() {
      const { width, height } = this.model
      const style = {
        width: `${width}px`,
        height: `${height}px`
      }
      if (this.dataLoading) style.position = 'relative'
      return style
    },
    chartTheme() {
      const colors = colorGroups.find(item => item['name'] === this.model.chartTheme)
      const params = {}
      if (colors) params.colors = colors.colors
      return GenerateTheme(params)
    },
    chartHeight() {
      return this.model.height
    },
    chartType() {
      return this.model.chartType
    }
  },
  methods: {
    parseKeyword() {
      const params = parseKeywordParams(this.richReportModel, this.model, this.interaction)
      if (_.isEmpty(params.keywords)) return
      if (_.isEmpty(params.dataRegionId)) return
      this.dataLoading = true
      this.$api.speech.parseMixKeyword(params).then(res => {
        const { keywordMatch, reportDataRequest } = res
        // 未匹配到不继续
        if (keywordMatch.length > 0) {
          this.keywordMatch = keywordMatch
          this.reportDataRequest = reportDataRequest
          this.previousKeywordSearchHistoryId = reportDataRequest.keywordSearchHistoryId
          this.keywordMatched = new KeywordMatched(params.keywords.join(''), keywordMatch)
          this.adviseXAxis = this.keywordMatched['adviseDatetimeAxis']
          this.getReportData(reportDataRequest)
        }
      }).catch(() => {
      }).finally(() => {
        this.dataLoading = false
      })
    },
    getReportData(reportDataRequest) {
      this.dataLoading = true
      this.$api.speech.getReportData(reportDataRequest).then(res => {
        this.reportData = res[0]
        this.reportChartData = new ReportChartData(res[0])
        this.setPreviewData()
        this.ready = false
        this.readyDelay()
      }).catch(error => {
        console.log(error)
        this.emptyData = true
      }).finally(() => {
        this.dataLoading = false
      })
    },
    setPreviewData() {
      if (!this.reportChartData) return
      const _result = this.reportChartData.keyValueToString()
      // eslint-disable-next-line vue/no-mutating-props
      this.model.previewData = _result.map(i => `${i['value']}${i['unit']}`).join('，')
    },
    onChartRendered(thumb) {
      if (!this._events.onChartRendered) return
      const id = this.model.id
      this.$emit('onChartRendered', { id, thumb })
    },
    setWidthHeight(width, height) {
      // eslint-disable-next-line vue/no-mutating-props
      this.model.width = width
      // eslint-disable-next-line vue/no-mutating-props
      this.model.height = height
      this.ready = false
      this.readyDelay()
    },
    chartInteractive(arg) {
      if (!this.model.interactive) return
      console.log('DashboardChart.chartInteractive', arg)
      const { action, params } = arg
      if (action === 'AppendKeyword') {
        const keywordList = params.keywordList.map(k => k['matchedValue'])
        this.parseKeyword(keywordList, true)
      }
    },
    render() {
      this.parseKeyword()
    },
    /**
     * 重新渲染
     * 用于 chartType 变化，图表 chartTheme 变化
     * 仅当数据加载完成后生效
     */
    rerender() {
      if (this.reportChartData) {
        this.ready = false
        this.readyDelay()
      }
    }
  },
  mounted() {
    const width = this.$parent.$parent.$el.offsetWidth - 82
    const height = width / this.model.width * this.model.height
    console.log('this.$parent.$el', this.$parent.$parent.$el)
    console.log('width', width)
    console.log('height', height)
    this.setWidthHeight(width, height)
    this.render()
    this.$emit('chartReady')
  },
  created() {
    this.parseKeywordDelay = debounce(this.parseKeyword, 300)
    this.readyDelay = debounce(() => {
      if (this.reportChartData) this.ready = true
    }, 300)
    this.$eventBus.$on('RICH_REPORT_INTERACT', this.chartInteractive)
  },
  destroyed() {
    this.$eventBus.$off('RICH_REPORT_INTERACT', this.chartInteractive)
  },
  watch: {
    'model.chartType'() {
      this.rerender()
    }
  }
}
</script>
<style lang="less">
.chart-chart {
  display: inline-block;
  overflow: hidden;
  border: 1px solid #eee;

  .loading {
    display: flex;
    background: rgba(200, 200, 200, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
  }
}
</style>
