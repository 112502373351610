<template>
  <span class="dimension-chart" :style="model.style">{{ displayData }}</span>
</template>

<script>
import _ from 'lodash'
import {
  RichReportChartInteractionModel,
  RichReportChartModel,
  RichReportModel
} from '@/views/richReport/models'

export default {
  name: 'DimensionChart',
  components: {
  },
  props: {
    model: {
      type: RichReportChartModel,
      default () {
        return new RichReportChartModel()
      }
    },
    richReportModel: {
      type: RichReportModel,
      default() {
        return new RichReportModel()
      }
    },
    interaction: {
      type: RichReportChartInteractionModel,
      default () {
        return new RichReportChartInteractionModel()
      }
    }
  },
  data () {
    return {
      displayData: ''
    }
  },
  computed: {
  },
  methods: {
    parseData() {
      // 不带key，如果value为空则不显示
      const { id, keyword, showKeyword } = this.model
      const key = keyword
      let value = ''
      let displayData = ''
      const tmpArr = []
      console.log('this.interaction', this.interaction.keywordList.length)
      this.interaction.keywordList.forEach(k => {
        console.log('k', k)
        console.log('key', key)
        if (k['relationKeyword'] === key) {
          tmpArr.push(k['keywordInput'])
        }
      })
      console.log('tmpArr', tmpArr)
      if (!_.isEmpty(tmpArr)) {
        value = tmpArr.join('、')
      }
      if (_.isEmpty(value)) {
        displayData = showKeyword ? `${key}：不限${key}` : ''
      } else {
        displayData = showKeyword ? `${key}：${value}` : value
      }
      this.displayData = displayData
      this.$emit('onChartRendered', { id, displayData })
    }
  },
  mounted() {
    this.parseData()
    this.$emit('chartReady')
    this.$eventBus.$on('RICH_REPORT_CHART_INTERACTIVE', this.parseData)
  },
  destroyed() {
    this.$eventBus.$off('RICH_REPORT_CHART_INTERACTIVE', this.parseData)
  }
}
</script>
