<template>
  <span class="datetime-chart" :style="model.style">{{ displayData }}</span>
</template>

<script>
import { RichReportChartInteractionModel, RichReportChartModel, RichReportModel } from '@/views/richReport/models'

export default {
  name: 'DatetimeChart',
  components: {
  },
  props: {
    model: {
      type: RichReportChartModel,
      default () {
        return new RichReportChartModel()
      }
    },
    richReportModel: {
      type: RichReportModel,
      default() {
        return new RichReportModel()
      }
    },
    interaction: {
      type: RichReportChartInteractionModel,
      default () {
        return new RichReportChartInteractionModel()
      }
    }
  },
  data () {
    return {
      displayData: ''
    }
  },
  methods: {
    parseData() {
      const { id, previewData } = this.model.previewData
      let datetimeKeyword = previewData
      if (this.interaction.datetime && this.interaction.datetime.keyword) {
        datetimeKeyword = this.interaction.datetime.keyword
      }
      this.displayData = datetimeKeyword
      this.$emit('onChartRendered', { id, displayData: this.displayData })
    }
  },
  mounted() {
    this.parseData()
    this.$emit('chartReady')
    this.$eventBus.$on('RICH_REPORT_CHART_INTERACTIVE', this.parseData)
  },
  destroyed() {
    this.$eventBus.$off('RICH_REPORT_CHART_INTERACTIVE', this.parseData)
  }
}
</script>
