import store from '@/store'
import api from '@/api'
import _ from 'lodash'
import { DATE_INTERACTIONS } from '@/views/richReport/models'

export const parseKeywordDateRange = (keyword, dataRegionId1) => {
  return new Promise((resolve, reject) => {
    const dataRegionId = dataRegionId1 || store.state.speech.dataRegionId
    if (!keyword) {
      reject(new Error('时间关键词不能为空'))
    } else if (!dataRegionId) {
      reject(new Error('未设置可用数据域'))
    } else {
      const data = {
        keywords: [keyword],
        dataRegionId: dataRegionId,
        printDataRequest: false,
        ignoreHistory: true
      }
      api.speech.parseMixKeyword(data)
        .then(res => {
          const { keywordMatch } = res
          if (_.isArray(keywordMatch) && keywordMatch.length > 0) {
            // "dateRange": {
            //   "start": "2021-01-01T00:00:00.000+08:00",
            //   "end": "2021-12-31T23:59:59.999+08:00",
            //   "dateFieldType": "YEAR",
            //   "single": true
            // }
            const exist = keywordMatch.find(k => k.dateRange !== undefined)
            if (exist) {
              resolve(exist.dateRange)
            } else {
              reject(new Error('无可用时间范围或者基点'))
            }
          }
        })
        .catch(e => {
          reject(new Error('解析关键词出错'))
        })
    }
  })
}

/**
 * 交互式解析关键词请求参数
 * @param richReportModel {RichReportModel} 不可为空
 * @param richReportChartModel {richReportChartModel} 不可为空
 * @param interaction {RichReportChartInteractionModel} 不可为空
 */
export const parseKeywordParams = (richReportModel, richReportChartModel, interaction) => {
  const { dataRegionId } = richReportModel
  const { keyword: modelKeyword, dateInteraction, params: modelParams } = richReportChartModel
  if (_.isEmpty(modelKeyword)) return null
  const params = {
    keywords: [modelKeyword],
    dataRegionId,
    previousKeywordSearchHistoryId: null,
    printDataRequest: true,
    ignoreHistory: true
  }
  if (dateInteraction === DATE_INTERACTIONS.MAX || dateInteraction === DATE_INTERACTIONS.MIN) {
    // 取时间点为基点
    if (interaction.datetime) {
      const useEnd = dateInteraction === DATE_INTERACTIONS.MAX
      params['baseDate'] = interaction.datetime.cloneBaseDateWithSetUseEnd(useEnd)
    }
  } else if (dateInteraction === DATE_INTERACTIONS.RANGE) {
    // 作为时间范围
    if (interaction.datetime) params.keywords.push(interaction.datetime.keyword)
  }
  // 图表配置的过滤参数
  modelParams.forEach(param => {
    const { key, value } = param
    // 交互的关键词，先判断是否被图表允许
    const existKeyword = interaction.keywordList.find(k => k['relationKeyword'] === key)
    if (existKeyword) {
      params.keywords.push(existKeyword['keywordInput'])
    } else if (value) {
      params.keywords.push(value)
    }
  })
  return params
}

/**
 * 解析日期关键词
 * @param keyword
 * @param dataRegionId
 * @returns {Promise<unknown>}
 */
export const parseKeywordDate = (keyword, dataRegionId) => {
  return new Promise((resolve, reject) => {
    if (_.isEmpty(keyword)) {
      resolve('')
    } else {
      api.speech.parseMixKeyword({
        keywords: [keyword],
        dataRegionId,
        printDataRequest: false,
        ignoreHistory: true
      }).then(res => {
        const { keywordMatch } = res
        const datetimeArr = []
        keywordMatch.forEach(k => {
          if (k['keywordCategory'] === 'DATETIME') {
            datetimeArr.push(k)
          }
        })
        if (datetimeArr.length === 0) {
          reject(new Error('未包含合法的时间日期'))
        } else if (datetimeArr.length > 1) {
          reject(new Error('存在多个时间日期'))
        } else {
          resolve(datetimeArr)
        }
      }).catch(error => {
        reject(error)
      })
    }
  })
}
