<template>
  <div class="message-detail">
    <van-nav-bar
      title="消息详情"
      left-arrow
      placeholder
      @click-left="onClickLeft"
      :style="{'padding-top': statusBarHeight + 'px'}"
    />
    <template v-if="messType === 'richReportNotice'">
      <RichReportComponent
        :richModel="richModel"
        :active="true"
        :messDetail="true"
        :keywordSearchHistoryId="keywordSearchHistoryId"/>
    </template>
    <template v-else>
      <van-cell v-if="detailMessage[0]" :style="{height: `calc(100vh - 90px - ${moreHeight}px)`}">
        <template slot="default">
          <div class="message-list-title">
            <div style="display: inline-block">{{ detailMessage[0].noticeSubject }}</div>
            <div style="color: #6d6d6d">{{ detailMessage[0].noticeTime | moment("YYYY-MM-DD HH:mm:ss") }}</div>
          </div>
          <div class="message-list-content">
            <div>{{ detailMessage[0].noticeSubject }}，
              <span v-if="detailMessage[0].conditions.length">通知条件为
                <span v-for="(condition, index) in detailMessage[0].conditions" :key="index" style="color: #656565"> {{ condition }}
                  <span v-if="index !== detailMessage[0].conditions.length-1">{{ detailMessage[0].connection }}</span>
                </span>
              </span>
              <span v-else>无通知条件</span>
              ，信息如下，请点击
              <span class="message-list-link" @click="showDetailMess(detailMessage[0].dataRequest)">查看详情</span>
              跳转至查询数据页面。</div>
            <MessageTable :tableData="detailMessage[0].tableData"/>
          </div>
        </template>
      </van-cell>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import KeywordMatched from '@/utils/keywordMatched'
import MessageTable from '@/components/message/MessageTable'
import RichReportComponent from '@/views/richReport/view/RichReportComponent'
export default {
  name: 'MessageDetail',
  components: {
    MessageTable,
    RichReportComponent
  },
  data () {
    return {
      connectionArray: [{ text: '大于', code: 'gt' }, { text: '小于', code: 'lt' }, { text: '等于', code: 'eq' },
        { text: '不等于', code: 'ne' }, { text: '大于等于', code: 'ge' }, { text: '小于等于', code: 'le' },
        { text: '在...和...之间', code: 'between' }],
      detailMessage: [],
      messType: '',
      richModel: {},
      keywordMatched: null,
      keywordSearchHistoryId: null
    }
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight,
      dataRegionId: state => state.dataRegion.activeItem.id
    })
  },
  mounted () {
    const noticeId = this.$route.params.noticeId
    let detailUrl = this.$route.params.detailUrl
    const dataContent = this.$route.params.dataContent
    if (dataContent) {
      this.messType = 'richReportNotice'
      this.getRichReportData(dataContent)
    } else {
      this.messType = 'warnNotice'
      detailUrl = detailUrl.replace(/\(|\)/g, '')
      this.$api.earlyWarning.noticeResults(detailUrl).then(res => {
        this.formatData(res)
      }).catch((err) => {
        if ((err + '').includes('404')) {
          this.$toast.fail('预警任务已失效')
        } else {
          this.$toast.fail('加载失败')
        }
      })
      if (noticeId) {
        this.$api.earlyWarning.markNoticeRead({ markRead: true }, noticeId).then(res => { // 标记已读
          this.$eventBus.$emit('reduceUnreadNum') // 未读减一
        }).catch(() => {})
      }
    }
  },
  methods: {
    formatData(res) {
      this.detailMessage.splice(0)
      const conditionPreview = res.noticeContentPreview['预警条件']
      const detailDataPreview = res.noticeContentPreview['详情数据']
      const key = Object.keys(conditionPreview)[0]
      const conditions = []
      const tableData = {
        columns: [],
        dataItems: []
      }
      conditionPreview[key].forEach(condition => {
        if (condition['op'] === 'between') {
          conditions.push(condition['field'] + '在' + condition['value'][0] + '和' + condition['value'][1] + '之间')
        } else {
          const op = this.connectionArray.find(op => op.code === condition['op'])
          conditions.push(condition['field'] + op.text + condition['value'])
        }
      })
      if (detailDataPreview.length) {
        Object.keys(detailDataPreview[0]).forEach((key, index) => {
          tableData['columns'].push({
            title: key,
            key: index
          })
        })
        tableData['dataItems'] = detailDataPreview
      }
      this.detailMessage.push({
        ...res,
        tableData: tableData,
        conditions: conditions,
        connection: key === 'and' ? '并且' : '或者'
      })
    },
    onClickLeft () {
      // this.$router.push({ name: 'MessageIndex' })
      window.history.go(-1)
    },
    showDetailMess (dataRequest) {
      this.$store.commit('speech/setHistoryLocation', { dataRegionId: this.dataRegionId, historyLocation: -1 })
      this.$router.push({ name: 'Speech', params: { dataRequest: dataRequest } })
      // 清空之前的搜索历史
      // this.$store.dispatch('speech/removeSearchHistory', dataRequest.dataRegionId).then(() => {
      //   this.$router.push({ name: 'Speech', params: { dataRequest: dataRequest } })
      // })
    },
    getRichReportData (dataContent) {
      const keywordMatch = dataContent.keywordMatch
      const keyword = dataContent.searchWords
      const richReportViewKeyword = keywordMatch.find(k => k['keywordCategory'] === 'REPORT_VIEW' && k['relationEntityId'])
      // step 1 判断是否有分析报告关键词
      const richReportId = richReportViewKeyword['relationEntityId']
      this.richModel = {
        id: richReportId,
        keyword: keyword,
        paramList: keywordMatch
      }
      this.keywordSearchHistoryId = dataContent.keywordSearchHistoryId
      this.keywordMatched = new KeywordMatched(keyword, keywordMatch)
    }
  }
}
</script>
<style lang="less">
.message-detail {
  background-color: #f8f8f8;
  .van-nav-bar {
    background-image: url("~@/assets/background.png");
    background-size: 100%;
    background-repeat: no-repeat;
    // &::after {
      // border-bottom-width: 0
    // }
    .van-nav-bar__content {
      height: 40px;
      .van-nav-bar__title {
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        font-family: PingFang SC;
      }
      .van-nav-bar__left {
        .van-icon-arrow-left {
          color: #000000;
          font-weight: bold;
          color: #ffffff;
          &::before {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .message-list-title {
    // display: flex;
    // justify-content: space-between;
  }
  .message-list-content {
    margin-top: 4px;
    color: #969799;
    font-size: 12px;
    line-height: 18px;
    .message-list-link {
      color: #1890ff
    }
  }
  .delete-button {
    height: 100%;
  }
}
</style>
