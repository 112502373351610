<template>
  <div class="key-value" :style="{height: height + 'px'}">
    <div v-if="dataSource.length === 0">暂无数据</div>
    <div class="key-value-item" v-for="item in dataSource" :key="item['key']">
      <div class="value">{{ item['value'] }} {{ item['unit'] }}</div>
      <div class="name">{{ item['name'] }}</div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { formatNumberThousands } from '@/utils/numberUtil'
export default {
  name: 'KeyValueChart',
  components: {
  },
  props: {
    reportData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: Number,
      default: 300
    }
  },
  computed: {
    dataItems () {
      if (this.reportData['dataItems'] && this.reportData['dataItems'].length > 0) {
        return this.reportData['dataItems'][0]
      } else {
        return {}
      }
    },
    dataUnits () {
      return this.reportData['units'] || []
    },
    dataSource () {
      const _result = []
      const _keys = Object.keys(this.dataItems)
      _keys.forEach((_key) => {
        if (!this.reportData['dimensions'].includes(_key)) {
          const _val = this.dataItems[_key]
          _result.push({
            key: uuidv4(),
            name: _key,
            value: formatNumberThousands(_val),
            unit: this.getUnitByMetrics(_key)
          })
        }
      })
      return _result
    }
  },
  methods: {
    getUnitByMetrics (metrics) {
      const _exist = this.dataUnits.find(item => item['metrics'].indexOf(metrics) > -1)
      return _exist ? _exist['unit'] : ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('chartRendered')
    })
  }
}
</script>
<style lang="less">
  .key-value {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .key-value-item {
      flex: 1;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      border-top: 1px solid #efefef;

      &:first-child {
        border: none;
      }
    }
    .key-value-item .value {
    }
    .key-value-item .name {
      font-size: 0.75em;
      opacity: 0.65;
    }
  }
</style>
