<template>
  <div :sylte="{ height: chartHeight + 'px' }">
    <key-value-chart
      v-if="chartType === 'KeyValue'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <pie-chart
      v-if="chartType === 'Pie'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <ring-chart
      v-if="chartType === 'Ring'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <bar-chart
      v-if="chartType === 'Bar'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <bar-chart
      v-if="chartType === 'BarGroup'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      seriesType="Group"
      @chartRendered="onChartRendered"/>

    <MultiFacetChart
      v-if="chartType === 'FacetColumnTable'"
      chartType="Column"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <SimpleRectChart
      v-if="chartType === 'ColumnGroup'"
      chartType="Column"
      seriesType="Group"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <SimpleRectChart
      v-if="['Line', 'Column', 'Area', 'LineColumn'].indexOf(chartType) !== -1"
      :chartType="chartType"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <MultiFacetChart
      v-if="chartType === 'FacetLineTable'"
      chartType="Line"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <MultiFacetChart
      v-if="chartType === 'FacetLineColumnTable'"
      chartType="LineColumn"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <radar-chart
      v-if="chartType === 'Radar'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <tree-chart
      v-if="chartType === 'Radar'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <table-chart
      v-if="chartType === 'Table'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <facet-chart
      v-if="chartType === 'FacetLine'"
      chart-type="Line"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :chartHeight="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <facet-chart
      v-if="chartType === 'FacetColumn'"
      chart-type="Column"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :chartHeight="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <facet-chart
      v-if="chartType === 'FacetBar'"
      chart-type="Bar"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :chartHeight="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <facet-chart
      v-if="chartType === 'FacetArea'"
      chart-type="Area"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :chartHeight="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <facet-chart
      v-if="chartType === 'FacetLineColumn'"
      chart-type="LineColumn"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :chartHeight="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <bar-table-chart
      v-if="chartType === 'BarTable'"
      chart-type="Bar"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :chartHeight="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <tree-diagram-chart
      v-if="chartType === 'Tree'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <bubble-chart
      v-if="chartType === 'Bubble'"
      :reportData="reportChartData"
      :xAxis="adviseXAxis"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>

    <point-chart
      v-if="chartType === 'Point'"
      :reportData="reportChartData"
      :height="chartHeight"
      :theme="chartTheme"
      @chartRendered="onChartRendered"/>
  </div>
</template>
<script>
import KeyValueChart from '../chart/KeyValueChart'
import NumberChart from '../chart/NumberChart'
import TableChart from '../chart/TableChart'
import PieChart from '@/components/charts/PieChart'
import RingChart from '@/components/charts/RingChart'
import BarChart from '@/components/charts/BarChart'
// import LineChart from '@/components/charts/LineChart'
// import LineColumnChart from '@/components/charts/LineColumnChart'
// import ColumnChart from '@/components/charts/ColumnChart'
import FsColumnChart from '@/components/charts/FsColumnChart'
// import AreaChart from '@/components/charts/AreaChart'
import RadarChart from '@/components/charts/RadarChart'
import FacetChart from '@/components/charts/FacetChart'
import TreeChart from '@/components/charts/TreeChart'
import BubbleChart from '@/components/charts/BubbleChart'
import PointChart from '@/components/charts/PointChart_new'
import BarTableChart from '@/components/charts/BarTableChart'
import TreeDiagramChart from '@/components/charts/TreeDiagramChart'
import SimpleRectChart from '@/components/charts/SimpleRectChart'
import MultiFacetChart from '@/components/charts/MultiFacetChart'
import html2canvas from 'html2canvas'
import ReportChartData from '@/utils/reportData'

export default {
  name: 'ChartAll',
  components: {
    NumberChart,
    KeyValueChart,
    PieChart,
    RingChart,
    // LineChart,
    // LineColumnChart,
    // ColumnChart,
    FsColumnChart,
    // AreaChart,
    RadarChart,
    TreeChart,
    BubbleChart,
    FacetChart,
    BarChart,
    TableChart,
    TreeDiagramChart,
    PointChart,
    BarTableChart,
    SimpleRectChart,
    MultiFacetChart
  },
  props: {
    reportChartData: {
      type: ReportChartData,
      default () {
        return null
      }
    },
    chartType: {
      type: String,
      default: ''
    },
    adviseXAxis: {
      type: String,
      default: ''
    },
    chartHeight: {
      type: Number,
      default: 0
    },
    chartTheme: {
      type: Object,
      default() {
        return null
      }
    }
  },
  methods: {
    onChartRendered() {
      if (!this._events.onChartRendered) return
      const _this = this
      html2canvas(this.$el).then(canvas => {
        const thumb = canvas.toDataURL('image/png')
        _this.$emit('onChartRendered', thumb)
      }).catch(error => {
        console.error(error)
      })
    }
  }
}
</script>
