<template>
  <div v-if="ready" id="RichReportView" class="rich-report-component">
    <template v-if="active">
      <div class="rich-report-component-inner">
        <van-cell class="rich-report-property" @click="clickTitle" v-if="!messDetail">
          <div slot="title">数据</div>
          <!-- <div slot="title">时间：{{ reportDatetime }}</div> -->
          <div slot="extra" class="extra">
            <!-- <template v-if="reportChartData['metrics'].length">
          <EarlyWarningDialog
            :metricsArray="reportChartData['metrics']"
            :keywordString="keywordString"
            :keywordSearchHistoryId="keywordSearchHistoryId"
            ref="refEarlyWarningDialog"/>
        </template> -->
            <van-popover
              class="smart-chart-action"
              ref="SmartChartAction"
              v-model="showAction"
              :actions="actionOptions"
              placement="bottom-end"
              @select="onActionSelect"
              trigger="click">
              <template #reference>
                <van-button size="mini" class="operate-btn">
                  <div class="operate-box">
                    <div class="operate-div">
                    </div>
                    <div class="operate-div">
                    </div>
                    <div class="operate-div">
                    </div>
                  </div>
                </van-button>
              </template>
            </van-popover>
          </div>
        </van-cell>
        <div class="rich-report-content">
          <div class="rich-report-time">
            时间：{{ reportDatetime }}
          </div>
          <div ref="refReportViewContent" v-html="model.content" :style="contentStyle"></div>
        </div>
      </div>
      <div class="charts-list">
        <template v-for="chartModel in model.charts">
          <metrics-chart
            v-if="chartModel.type === 'metrics'"
            :key="chartModel['id']"
            :ref="'chart' + chartModel['id']"
            :model="chartModel"
            :rich-report-model="model"
            :interaction="interaction"
            @chartReady="onChartReady(chartModel)" />
          <dimension-chart
            v-if="chartModel.type === 'dimension'"
            :key="chartModel['id']"
            :ref="'chart' + chartModel['id']"
            :model="chartModel"
            :rich-report-model="model"
            :interaction="interaction"
            @chartReady="onChartReady(chartModel)" />
          <datetime-chart
            v-if="chartModel.type === 'datetime'"
            :key="chartModel['id']"
            :ref="'chart' + chartModel['id']"
            :model="chartModel"
            :rich-report-model="model"
            :interaction="interaction"
            @chartReady="onChartReady(chartModel)" />
          <chart-chart
            v-if="chartModel.type === 'chart'"
            :key="chartModel['id']"
            :ref="'chart' + chartModel['id']"
            :model="chartModel"
            :rich-report-model="model"
            :interaction="interaction"
            @chartReady="onChartReady(chartModel)" />
        </template>
      </div>
    </template>
    <van-cell
      v-else
      title="数据"
      is-link
      arrow-direction="down"
      class="folded-title"
      @click="$emit('ifShowContent')" />
    <WxShareTips ref="refWxShareTips" />
  </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import ChartChart from './ChartChart'
import MetricsChart from './MetricsChart'
import DimensionChart from './DimensionChart'
import DatetimeChart from './DatetimeChart'
import { mapState } from 'vuex'
import { RichReportChartInteractionModel, RichReportModel } from '../models'
import { ATTACHMENT_PREFIX } from '@/config'
import WxShareTips from '@/components/global/WxShareTips'

/**
 * step 1, 根据ID读取报告内容
 * step 2, 解析报告中的时间属性，如果没有设置，直接下一步
 * step 3, 解析URL传参、组件传参中的时间、维度等关键词，有则覆盖上一步中的时间结果，没有则下一步
 * step 4,
 */
export default {
  name: 'RichReportComponent',
  components: {
    MetricsChart,
    DimensionChart,
    DatetimeChart,
    ChartChart,
    WxShareTips
  },
  props: {
    richModel: {
      type: Object,
      default () {
        return new RichReportModel()
      }
    },
    queryKeyword: {
      type: String,
      default: ''
    },
    keywordSearchHistoryId: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    messDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      replaced: false,
      interaction: new RichReportChartInteractionModel(),
      model: null,
      ready: false,
      showAction: false,
      btnOptions: [
        { text: '加入常用搜索', icon: 'todo-list-o', value: 2 },
        { text: '移除常用搜索', icon: 'todo-list-o', value: 3 },
        { text: '加入首页显示', icon: 'wap-home-o', value: 4 },
        { text: '移除首页显示', icon: 'wap-home-o', value: 5 },
        { text: '分享', icon: 'share-o', value: 6 }
      ],
      actionOptions: [],
      showPopover: false
    }
  },
  computed: {
    ...mapState({
      inModule: state => state.speech.inModule,
      dataRegionId: state => state.dataRegion.activeItem.id
    }),
    contentStyle () {
      return {
        opacity: this.replaced ? 1 : 0
      }
    },
    reportDatetime () {
      if (this.interaction && this.interaction.datetime && this.interaction.datetime.keyword) {
        return this.interaction.datetime.keyword
      } else {
        return moment().format('YYYY年MM月DD日')
      }
    }
  },
  methods: {
    /**
     * 解析关键词
     * 日期时间，或者是维过滤
     * @param {String|Array} keywords
     */
    parseKeywords(keywords) {
      return new Promise((resolve, reject) => {
        if (!_.isEmpty(keywords)) {
          if (_.isString(keywords)) {
            this.$api.speech.parseMixKeyword({
              keywords: [keywords],
              dataRegionId: this.dataRegionId,
              printDataRequest: false,
              ignoreHistory: true
            }).then(res => {
              const { keywordMatch } = res
              if (_.isArray(keywordMatch)) {
                resolve(new RichReportChartInteractionModel(keywordMatch))
              } else {
                reject(new Error('分析报告交互关键词解析出错'))
              }
            }).catch(error => {
              reject(error)
            })
          } else if (_.isArray(keywords)) {
            // 关键词列表 paramList
            resolve(new RichReportChartInteractionModel(keywords))
          } else {
            reject(new Error('分析报告交互关键词类型只能为字符串或数组'))
          }
        } else {
          reject(new Error('分析报告交互关键词不能为空'))
        }
      })
    },
    /**
     * 读取报告
     * step 1
     */
    initData() {
      if (!this.richModel.id) return
      this.loading = true
      this.$api.dashboard.getDashboardById(this.richModel.id).then(res => {
        this.model = new RichReportModel(res)
        this.parseDatetime()
        this.loading = false
        this.$emit('rendered')
        if (this.model.charts.length === 0) this.replaced = true
      }).catch(error => {
        this.loading = false
        console.log(error)
        this.$message.error(error)
      })
    },
    /**
     * step 2
     */
    parseDatetime() {
      if (this.model.datetime) {
        this.parseKeywords(this.model.datetime).then(interaction => {
          this.interaction.appendInteraction(interaction)
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.parseQueryKeyword()
        })
      } else {
        this.parseQueryKeyword()
      }
    },
    /**
     * step 3
     */
    parseQueryKeyword() {
      if (this.queryKeyword) {
        this.parseKeywords(this.queryKeyword).then(interaction => {
          this.interaction.appendInteraction(interaction)
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.ready = true
        })
      } else if (!_.isEmpty(this.richModel.paramList)) {
        this.parseKeywords(this.richModel.paramList).then(interaction => {
          this.interaction.appendInteraction(interaction)
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.ready = true
        })
      } else {
        this.ready = true
      }
    },
    /**
     * 图表还原成功
     * 图表对象替换到内容区
     * @param {RichReportChartModel} chart
     */
    onChartReady(chart) {
      const { charts } = this.model
      const { id } = chart
      const exist = document.getElementById(id)
      const existParent = exist.parentNode
      const ref = this.$refs[`chart${id}`][0].$el
      existParent.replaceChild(ref, exist)
      chart.replaced = true
      console.log('1..................................')
      if (charts.filter(item => item['replaced']).length === charts.length) {
        console.log('..................................')
        this.replaced = true
      }
    },
    /**
     * 语音交互
     */
    onDrtCommonSearch(params) {
      const { keywordInput } = params
      if (!_.isEmpty(keywordInput)) {
        this.ready = false
        this.parseKeywords(keywordInput).then(interaction => {
          this.interaction.appendInteraction(interaction)
          this.ready = true
          this.$eventBus.$on('RICH_REPORT_CHART_INTERACTIVE', this.interaction)
        })
      }
    },
    getSearchTarckExists () {
      const searchWords = this.richModel.keyword
      const dataRegionId = this.dataRegionId
      console.log('a', searchWords)
      if (searchWords && dataRegionId) {
        const getIfHeadlines = new Promise((resolve, reject) => {
          this.$api.speech.searchTrackExists('headlines', { // 是否加入首页
            searchWords: searchWords,
            dataRegionId: dataRegionId
          }).then((headlinesRes) => {
            // this.isHeadlines = headlinesRes.collected
            resolve(headlinesRes.collected)
          })
        })
        const getIfCollections = new Promise((resolve, reject) => {
          this.$api.speech.searchTrackExists('collections', { // 是否收藏
            searchWords: searchWords,
            dataRegionId: dataRegionId
          }).then((collectionRes) => {
            resolve(collectionRes.collected)
          })
        })
        Promise.all([getIfHeadlines, getIfCollections]).then((isHeadlines, isCollection) => {
          this.initActionOptions(isHeadlines, isCollection)
        })
      }
    },
    initActionOptions ([isHeadlines, isCollection]) {
      this.actionOptions = _.cloneDeep(this.btnOptions)
      if (isHeadlines) {
        this.actionOptions.splice(2, 1)
      } else {
        this.actionOptions.splice(3, 1)
      }
      if (isCollection) {
        this.actionOptions.splice(0, 1)
      } else {
        this.actionOptions.splice(1, 1)
      }
    },
    onActionSelect(action) {
      const { value } = action
      if (value === 2) {
        this.addFrequentSearch()
      } else if (value === 3) {
        this.removeFrequentSearch()
      } else if (value === 4) {
        this.addHomePage()
      } else if (value === 5) {
        this.removeHomePage()
      } else if (value === 6) {
        if (!this.thumbUrl) {
          this.$api.speech.collectKeywords({
            searchWords: this.richModel.keyword,
            dataRegionId: this.dataRegionId,
            keywordSearchHistoryId: this.keywordSearchHistoryId
          }).then((res) => {
            this.thumbUrl = ATTACHMENT_PREFIX + res['thumbnail']
            this.$refs.refWxShareTips.share({
              thumbUrl: this.thumbUrl,
              title: this.richModel.keyword
            })
          })
        } else {
          this.$refs.refWxShareTips.share({
            thumbUrl: this.thumbUrl,
            title: this.richModel.keyword
          })
        }
      }
    },
    // 添加到常用搜索
    addFrequentSearch () {
      this.$api.speech.collectKeywords({
        searchWords: this.richModel.keyword,
        dataRegionId: this.dataRegionId,
        keywordSearchHistoryId: this.keywordSearchHistoryId
      }).then((res) => {
        this.thumbUrl = res['thumbnail']
        this.$toast('已添加到常用搜索')
        this.$eventBus.$emit('FrequentSearchChange')
        this.actionOptions.splice(0, 1, this.btnOptions[1])
      })
    },
    // 添加到主页
    addHomePage () {
      this.$api.speech.collectHeadlines({
        searchWords: this.richModel.keyword,
        dataRegionId: this.dataRegionId,
        keywordSearchHistoryId: this.keywordSearchHistoryId
      }).then((res) => {
        this.thumbUrl = res['thumbnail']
        this.$toast('已添加到主页')
        this.actionOptions.splice(2, 1, this.btnOptions[3])
      })
    },
    // 删除常用搜索
    removeFrequentSearch () {
      this.$api.speech.cancelCollectKeywords({
        searchWords: this.richModel.keyword,
        dataRegionId: this.dataRegionId
      }).then(res => {
        this.$toast('已移除常用搜索')
        this.$eventBus.$emit('FrequentSearchChange')
        this.actionOptions.splice(0, 1, this.btnOptions[0])
      })
    },
    // 从主页移除
    removeHomePage () {
      this.$api.speech.cancelCollectHeadlines({
        searchWords: this.richModelmodel.keyword,
        dataRegionId: this.dataRegionId
      }).then(res => {
        this.$toast('已移除首页显示')
        this.actionOptions.splice(1, 1, this.btnOptions[2])
      })
    },
    clickTitle (e) {
      const refs = this.$refs
      console.log('refs', refs)
      console.log('3', e)
      if (refs['SmartChartAction']?.$el !== e.target.parentNode) {
        this.$emit('ifShowContent') // 打开tab内容
      }
    }
  },
  mounted() {
    this.initData()
    this.getSearchTarckExists()
    // this.$eventBus.$on('ModuleSpeechCommand', this.onSpeechCommand)
    this.$eventBus.$on('DRT_COMMON_SEARCH', this.onDrtCommonSearch)
  },
  destroyed() {
    // this.$eventBus.$off('ModuleSpeechCommand', this.onSpeechCommand)
    this.$eventBus.$off('DRT_COMMON_SEARCH', this.onDrtCommonSearch)
  }
}
</script>

<style lang="less" scoped>
.rich-report-component {
  .rich-report-component-inner {
    padding: 10px;
    .rich-report-content {
      padding: 10px 12px;
      // border: 1px solid #efefef;
    }
    .rich-report-property {
      // text-align: right;
      padding: 0;
      .van-cell__title {
        font-size: 15px;
        padding: 0 6px;
      }
      .extra {
        display: flex;
        justify-content: flex-end;
        width: 4em;
        .smart-chart-action {
          display: flex;
          align-items: center;
          .operate-btn {
            vertical-align: middle;
            font-size: 0.8rem;
            border: none;
            background-color: transparent;
            height: 16px;
            margin-bottom: 5px;
            .operate-box {
            margin-bottom: 3px;
              .operate-div {
                border-bottom: 1px solid #606061;
                height: 3px;
                width: 0.9em;
              }
            }
          }
        }
      }
    }
    .rich-report-time {
      font-size: 14px;
      text-align: right;
    }
  }

  .charts-list {
    position: absolute;
    top: 100px;
    left: -10000px;
  }

  .folded-title {
    font-size: 15px;
  }
}
</style>
